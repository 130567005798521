<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form ref="mark_upload_date_extension_form">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Academic Year') }}</span>
              </b-card-text>
              <validation-provider #default="{ errors }" name="Academic year" rules="required">
                <select v-model.number="academic_year_id" name="academic_year_id" class="form-control"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
                  <option :value="null">Select One</option>
                  <option v-for="(academic_year, key) in academic_years" :value="academic_year.id" :key="key">{{
                      academic_year.year
                  }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Class') }}</span>
              </b-card-text>
              <validation-provider #default="{ errors }" name="Class" rules="required">
                <select name="classes_id" v-model.number="classes_id" class="form-control"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
                  <option :value="null">Select One</option>
                  <option v-for="(cls, key) in classes" :value="cls.id" :key="key">{{ cls.name }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Wing') }}</span>
              </b-card-text>
              <validation-provider #default="{ errors }" name="Wing" rules="required">
                <select name="wing_id" v-model.number="wing_id" class="form-control"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
                  <option :value="null">Select One</option>
                  <option v-for="(wing, key) in wings" :value="wing.id" :key="key">{{ wing.name }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Examination Type') }}</span>
              </b-card-text>
              <validation-provider #default="{ errors }" name="Exam type" rules="required">
                <select v-model.number="exam_type_id" name="exam_type_id" class="form-control"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
                  <option :value="null">Select One</option>
                  <option v-for="(eType, key) in active_exam_types" :value="eType.id" :key="key">{{ eType.name }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Subject Name') }}</span>
              </b-card-text>
              <validation-provider #default="{ errors }" name="Subject name" rules="required">
                <select v-model.number="subject_id" name="subject_id" class="form-control"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
                  <option :value="null">Select One</option>
                  <option v-for="(subject, key) in active_subjects" :value="subject.id" :key="key">{{ subject.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Faculty Name') }}</span>
              </b-card-text>
              <validation-provider #default="{ errors }" name="Faculty name" rules="required">
                <select v-model.number="created_by" name="created_by" class="form-control"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
                  <option :value="null">Select One</option>
                  <option v-for="(employee, key) in employees" :value="employee.userable.id" :key="key">{{
                      employee.institute_emp_id ? employee.institute_emp_id : 'TBD'
                  }} - {{ employee.userable.name }}
                  </option>

                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Start Date') }}</span>
              </b-card-text>
              <validation-provider #default="{ errors }" name="Start date">
                <b-form-datepicker v-model="special_permission_start" name="special_permission_start"
                  :state="errors.length > 0 ? false : null" placeholder="select a date" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('End Date') }}</span>
              </b-card-text>
              <validation-provider #default="{ errors }" name="End date">
                <b-form-datepicker v-model="special_permission_end" name="special_permission_end"
                  :state="errors.length > 0 ? false : null" placeholder="select a date" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>

        <b-button v-if="$can('update', 'Permission')" variant="primary" type="submit" class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click.prevent="saveMarkUploadExtendedDate">
          Allow To Upload
        </b-button>

        <b-button v-if="$can('update', 'Permission')" variant="primary" type="submit" class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click.prevent="lockMarkUpload">
          Lock Upload
        </b-button>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import {
  BButton, BRow, BForm, BCol, BFormGroup, BCardText, BFormDatepicker
} from 'bootstrap-vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapGetters } from "vuex";
import { required } from '@validations'
import apiCall from "@/libs/axios";
export default {
  components: {
    BButton,
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker
  },
  data() {
    return {
      created_by: null,
      is_loading: false,
      subject_id: null,
      academic_year_id: null,
      wing_id:null,
      exam_type_id: null,
      classes_id: null,
      special_permission_start: null,
      special_permission_end: null,
      required,
    }
  },
  created() {
    if (this.employees.length < 1) this.$store.dispatch('GET_ALL_EMPLOYEE');
    this.$store.dispatch('GET_ALL_ACADEMIC_YEAR');
    this.$store.dispatch('GET_ALL_CLASSES');
    if (this.active_exam_types.length < 1) this.$store.dispatch('GET_ACTIVE_EXAM_TYPE');
    this.$store.dispatch('GET_ACTIVE_SUBJECT');

    if (this.wings.length < 1) this.$store.dispatch('GET_ALL_WING');
  },

  methods: {
    saveMarkUploadExtendedDate() {
      this.$refs.simpleRules.validate().then(success => {
        if (this.special_permission_start == null) {
          alert('Please select start date');
          return;
        }

        if (this.special_permission_end == null) {
          alert('Please select end date');
          return;
        }

        if (success) {
          let data = new FormData();
          data.append("academic_year_id", this.academic_year_id);
          data.append("classes_id", this.classes_id);
          data.append("exam_type_id", this.exam_type_id);
          data.append("subject_id", this.subject_id);
          data.append("wing_id", this.wing_id);
          data.append("created_by", this.created_by);
          data.append("special_permission_start", this.special_permission_start);
          data.append("special_permission_end", this.special_permission_end);
         
          this.is_loading = true;
          apiCall.post(`/employee/student/mark/upload/extension`, JSON.stringify(Object.fromEntries(data))).then((response) => {
            this.is_loading = false;
            if (response.data.status === 'success') {
              this.$toaster.success(response.data.message);
            } else {
              this.$toaster.error(response.data.message);
            }
          }).catch((error) => {
            this.is_loading = false;
            this.$toaster.success(error.data.message);
          })
        }
      })
    },

    lockMarkUpload() {

      this.$refs.simpleRules.validate().then(success => {

        if (success) {
          let data = new FormData();
          data.append("academic_year_id", this.academic_year_id);
          data.append("classes_id", this.classes_id);
          data.append("exam_type_id", this.exam_type_id);
          data.append("subject_id", this.subject_id);
          data.append("created_by", this.created_by);
          data.append("wing_id", this.wing_id);
          this.is_loading = true;
          apiCall.post(`/employee/student/mark/upload/lock`, JSON.stringify(Object.fromEntries(data))).then((response) => {
            this.is_loading = false;
            if (response.data.status === 'success') {
              this.$toaster.success(response.data.message);
            } else {
              this.$toaster.error(response.data.message);
            }
          }).catch((error) => {
            this.is_loading = false;
            this.$toaster.success(error.data.message);
          })
        }
      })
    }
  },
  computed: {

    employees() {
      let employeeList = [];
      for (let i = 0; i < this.$store.getters.employees.length; i++) {
        if (this.$store.getters.employees[i].userable != null && this.$store.getters.employees[i].userable.role_id == 2) {
          var emp = this.$store.getters.employees[i];
          var serialNumber = parseInt(this.$store.getters.employees[i].institute_emp_id.toString().split("-")[1]);
          emp.serialNumber = serialNumber;
          employeeList.push(emp);
        }
      }

      employeeList.sort((a, b) => {
          return a.serialNumber - b.serialNumber;
      });

      return employeeList;
    },

     active_exam_types() {
      return this.$store.getters.active_exam_types;
    },

    active_subjects() {
      let activeSubjectList = [];
      for (let i = 0; i < this.$store.getters.active_subjects.length; i++) {
        if (this.$store.getters.active_subjects[i].classes_id == this.classes_id) {
          activeSubjectList.push(this.$store.getters.active_subjects[i]);
        }
      }
      return activeSubjectList;
    },

    ...mapGetters(['academic_years', 'classes', 'active_exam_types', 'wings']),
  },
  watch: {
    employee_id() {
      this.findCurrentPermission();
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
